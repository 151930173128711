import { moduleRequest } from '../util/request'

const request = moduleRequest(`/sys/`)
/**
 * home
 *
 * @author yubaoshan
 * @date 2024-09-22 22:33:20
 */
export default {
    //修改用户头像
    updateAvatar(data) {
        return request('userCenter/updateAvatar', data)
    },
}