<template>
    <div class="order">
        <div class="content">
            <div class="top">
                <p>资金明细</p>
            </div>
            <div class="tip">
                <div class="left">
                    {{ timer }} <van-icon name="arrow-down" size="12" @click="show = true" />
                </div>
                <div class="right">
                    <van-button plain type="primary" round>导出</van-button>
                </div>
            </div>
            <div class="middle">
                <span class="income fz14">收入 ￥0</span>
                <span class="disbursement fz14">支取 ￥0</span>
            </div>
            <div class="bottom">
                <van-button round type="primary" class="active">全部</van-button>
                <van-button round type="primary">收入</van-button>
                <van-button round type="primary">支取</van-button>
            </div>
            <div class="floor fz14">
                -- 已显示全部流水 --
            </div>
        </div>
        <van-calendar v-model:show="show" @confirm="onConfirm" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            show: false,
            timer: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate()
        }
    },
    methods: {
        onConfirm(selectedValues) {
            this.show = false;
            this.timer = selectedValues.getFullYear() + "-" + (selectedValues.getMonth() + 1) + "-" + selectedValues.getDate();
        }
    }
}
</script>
<style lang="less" scoped>
.order {
    height: 100%;
    position: fixed;
    width: 100%;
    overflow-y: scroll;

    .content {
        position: absolute;
        width: 100%;
        font-family: SourceHanSansCN-Medium;
        z-index: 100;
        padding-bottom: 20px;

        .top {
            text-align: center;
            line-height: 50px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            background: #F1F1F1FF;
        }

        .middle,
        .tip,
        .bottom,
        .floor {
            width: 90%;
            margin: 0 auto;
        }

        .income {
            margin-right: 15px;
        }

        .bottom {
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px dashed #F1F1F1FF;

            .van-button {
                height: 28px;
                background: #F1F1F1FF;
                border: 1px solid #F1F1F1FF;
                color: black;
                padding: 0px 20px 0 20px;

                span {
                    font-size: 12px;
                }
            }

            .active {
                background: #fff1ee;
                border: 1px solid #fff1ee;

                span {
                    color: #ff2c00;
                }
            }

            .van-button:nth-child(1) {
                margin-right: 10px;
            }

            .van-button:nth-child(2) {
                margin-right: 10px;
            }
        }

        .tip {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            margin-top: 20px;

            .van-button {
                border: 1px solid rgb(246, 176, 26);
                color: rgb(246, 176, 26);
                height: 25px;
            }

            .left {
                font-weight: 700;
            }
        }

        .floor {
            margin-top: 30px;
            color: #c4c4c4;
            text-align: center;
        }
    }
}
</style>