<template>
    <div class="detail">
        <img src="../../public/10.png" alt="" class="limg" v-show="list.length">
        <div class="content">
            <div class="top">
                <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
                <p>查询兑换码</p>
                <div></div>
            </div>
            <div>
                <van-search v-model="value" shape="round" placeholder="请输入兑换码查询">
                    <template #right-icon>
                        <div>
                            <van-button size="small" round @click="onClick"
                                style="  background: linear-gradient(to right, #FF7A35, #FF4F33); color: white;padding: 0 20px 0 20px;">搜索
                            </van-button>
                        </div>
                    </template>
                </van-search>
            </div>

            <div class="middle" v-if="list.length > 0" v-for="(item, index) in list">
                <div class="topbox">
                    <div class="tip">
                        <div class="fz13" style="width: 85%;">兑换码ID:{{ item.ID }}</div>
                        <div class="fz13" style="color:#20B759;">{{ statusList[item.CODE_STATUS] }}</div>
                    </div>
                    <div class="http">
                        <div class="fz12 line">链接：{{ item.DOMAIN_NAME }}{{ item.CODE }}</div>
                        <div style="color:#666666" @click="copyText(item.CODE, item.DOMAIN_NAME)">
                            <span class="iconfont fz12"> &#xe603;</span>
                            <span class="fz12">复制</span>
                        </div>
                    </div>
                    <div class="redeem">
                        <div class="fz12 line">兑换码：{{ item.CODE }}</div>
                    </div>
                    <div class="bottom fz12">
                        有限期至：{{ item.endTime }}
                    </div>
                </div>
                <div v-for="(data, index) in item.oder" class="btobox">
                    <div class="floor">
                        <div class="justify-content">
                            <div>
                                <img :src="data.MEAL_IMG" alt="" style="width: 50px; height: 50px;margin-right: 10px;">
                            </div>
                            <div>
                                <div class="fz14">{{ data.MEAL_TITLE }}</div>
                                <!-- <div style="color:#999999">
                                    <div class="fz12" style="margin-top: 4px;">1x中薯条</div>
                                    <div class="fz12" style="margin-top: 4px;">1x原味圆筒冰淇淋</div>
                                    <div class="fz12" style="margin-top: 4px;">1x原味圆筒冰淇淋</div>
                                    <div class="fz12" style="margin-top: 4px;">1x原味圆筒冰淇淋</div>
                                </div> -->
                            </div>
                        </div>
                        <div>
                            <div class="fw">￥{{ data.PRICE_NUM }}</div>
                            <!-- <div class="fz12" style="color:#999999">原价<span class="line">￥{{ item.price }}</span></div> -->
                        </div>
                    </div>
                    <div class="floorbto fz14">
                        <div class="timer">
                            <div>使用时间</div>
                            <div>{{ item.USE_TIME ? timestampToTime(item.USE_TIME) : '' }}</div>
                        </div>
                        <div class="account">
                            <div>使用账户</div>
                            <div>{{ data.USER_IPHONE }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else></div>
        </div>
        <div class="floorb" v-if="list.length > 0 && list[0].status == 0">
            <div class="fr">
                <van-button plain round type="primary" color="#FF5633" style="height: 35px;"
                    @click="handleonClick">下架</van-button>
            </div>
        </div>
        <van-empty image="search" description="暂无订单" class="empty" v-if="list.length == 0" />
    </div>
</template>
<script>
import change from '@/api/change';
import { Toast } from 'vant';
// import { timestampToTime } from "@/util/clipboard"

export default {
    data() {
        return {
            value: '',
            list: [],
            statusList: [
                "待使用",
                "",
                "已关闭",
                "已使用"
            ]
        }
    },
    methods: {
        onClick() {
            this.list = []
            if (this.value) {
                change.condition(this.value).then(res => {
                    if (res) {
                        this.list.push(res[0])
                    }
                    console.log(this.list);
                })
            }
        },
        handleonClick() {
            change.down(this.list[0].cid).then(res => {
                Toast('已下架!');
                this.onClick()
            })
        },
        copyText(value1, value2) {
            navigator.clipboard.writeText('链接：' + value2 + value1 + " " + "兑换码：" + value1);
            Toast('已复制到剪贴板!');
        },
        //转时间戳
        timestampToTime(timestamp) {
            const date = new Date(Number(timestamp));

            // 获取日期和时间的各个部分
            const year = date.getFullYear();
            const month = `0${date.getMonth() + 1}`.slice(-2); // 月份是从0开始的，所以加1并补零
            const day = `0${date.getDate()}`.slice(-2);
            const hours = `0${date.getHours()}`.slice(-2);
            const minutes = `0${date.getMinutes()}`.slice(-2);
            const seconds = `0${date.getSeconds()}`.slice(-2);

            // 返回格式化的日期字符串 "YYYY-MM-DD HH:mm:ss"
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
    },
}
</script>
<style scoped lang="less">
.detail {
    height: 100%;
    background: #F1F1F1;
    position: fixed;
    width: 100%;
    overflow-y: scroll;

    .floorb {
        width: 100%;
        background: white;
        position: fixed;
        bottom: 0;
        z-index: 100;

        .fr {
            display: flex;
            align-items: center;
            padding: 10px;
            justify-content: right;
        }
    }

    .limg {
        width: 311px;
        float: right;
        position: relative;
    }

    .content {
        margin-top: 30px;
        position: absolute;
        width: 100%;
        z-index: 100;
        padding-bottom: 20px;

        .top {
            font-weight: bold;
            display: flex;
            width: 90%;
            margin: 0 auto;
            justify-content: space-between;
        }

        .van-search {
            border-radius: 999px;
            padding: 0;
            width: 90%;
            margin: 0 auto;
            margin-top: 20px;

            .van-search__content {
                background: white;
            }

            .van-cell {
                display: flex;
                align-items: center;
                padding: 3px;
            }
        }

        .middle {
            width: 90%;
            margin: 0 auto;
            box-sizing: border-box;
            margin-bottom: 55px;
        }

        .topbox {

            background: white;
            padding: 15px;
            border-radius: 10px;
        }

        .btobox {
            margin-top: 15px;
            background: white;
            padding: 15px;
            border-radius: 10px;
        }

        .middle {
            margin-top: 20px;
            box-sizing: border-box;

            .tip {
                display: flex;
                justify-content: space-between;
            }

            .http {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
                color: #999999;
            }

            .redeem {
                margin-top: 8px;
                color: #999999;
            }

            .bottom {
                background: #FFF2F0;
                border-radius: 13px;
                margin: 0 auto;
                color: #FE583C;
                text-align: center;
                margin-top: 25px;
                padding: 5px 0 5px 0;
            }
        }

        .floor {
            // margin-top: 20px;
            border-radius: 10px 10px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 5px;
        }

        .floorbto {
            color: #666666;
            border-radius: 0 0 10px 10px;
            background: white;
            margin: 0 auto;
            box-sizing: border-box;

            .timer {
                padding-bottom: 12px;
                padding-top: 12px;
            }

            .account {
                padding-top: 12px;
            }

            .timer,
            .account {
                display: flex;
                justify-content: space-between;
                border-top: 1px solid #F1F1F1;
            }
        }
    }
}

.empty {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}
</style>