import { moduleRequest } from '../util/request'

const request = moduleRequest(`/mdl/`)
/**
 * home
 *
 * @author yubaoshan
 * @date 2024-09-22 22:33:20
 */
export default {
    //兑换码详情
    getcdkdetail(data) {
        return request('setmeal/detail', data, 'get')
    },
    //关键字搜索
    getcdksearch(data) {
        return request('setmeal/page', data, 'get')
    },
    //获取全部订单
    getorderList(data){
		return request('getorderList',data,'post')
	}
}