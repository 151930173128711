import { render, staticRenderFns } from "./Money.vue?vue&type=template&id=6bde4267&scoped=true"
import script from "./Money.vue?vue&type=script&lang=js"
export * from "./Money.vue?vue&type=script&lang=js"
import style0 from "./Money.vue?vue&type=style&index=0&id=6bde4267&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bde4267",
  null
  
)

export default component.exports