import { render, staticRenderFns } from "./Close.vue?vue&type=template&id=052412c7&scoped=true"
import script from "./Close.vue?vue&type=script&lang=js"
export * from "./Close.vue?vue&type=script&lang=js"
import style0 from "./Close.vue?vue&type=style&index=0&id=052412c7&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "052412c7",
  null
  
)

export default component.exports