<template>
  <div class="wode">
    <div class="wotop">
      <div style="display: flex;justify-content: space-between;">
        <div class="left">
          <!-- <van-uploader v-model="fileUrl" :preview-image="false" :after-read="afterRead">
            <template>
              <img :src="useravatar" alt="" style="width: 80px;height: 80px;border-radius: 50%;"></img>
            </template>
          </van-uploader> -->
          <img src="https://tse4-mm.cn.bing.net/th/id/OIP-C.Ekyqq_T0BDXvef1bfI2qDQHaHa?rs=1&pid=ImgDetMain" alt="" style="width: 80px;height: 80px;border-radius: 50%;"></img>
          <div class="username" style="margin-left:15px;">
            <div>
              {{ userInfo.name }}
            </div>
            <div class="vip">
              <img src="../../public/VIP.png" alt="">
              <div class="viptitle">未开通</div>
            </div>
          </div>
        </div>
        <div class="right" style=" margin-top: 73px;">
          <div style="display: flex;;padding-left: 15px;margin-bottom: 7px;align-items: center;">
            <img src="../../public/设置.png" alt="" style="width: 16.45px;height: 15.54px;">
            <div style="margin-left: 4px;">设置</div>
          </div>
          <div class="duihuan" @click="handledetail">
            <div class="mingxi">
              <div style="color: white; font-size: 12px;margin-left: 15%;">资金明细</div>
              <div style="margin-left: 10%;"><img src="../../public/前进.png" alt="" style="width: 5px;height: 8px;">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom1">
      <div class="bimg" style="display:flex ; justify-content: space-between;">
        <div style="display: flex; margin-left: 8% ;margin-top: 15px; justify-content: center;">
          <img src="../../public/会员.png" alt="" style="width: 26px;height: 24px;">
          <div style="color: white; font-size: 15px; margin-left: 5px;">会员中心</div>
        </div>
        <div style="color: #F7DABB; font-size: 12px; margin-right: 8%;margin-top: 18px;">
          开通会员，尊享更多权益>
        </div>
      </div>
    </div>
    <div class="account">
      <div class="account-left">
        <div class="account-text">0</div>
        <div class="account-bo">可用余额(元)</div>
      </div>
      <div class="account-right">
        <div class="account-text">0</div>
        <div class="account-bo">账户加款</div>
      </div>
    </div>
    <div class="middle">
      <div class="moddle-title">
        我的订单
      </div>
      <div class="middle-img">
        <div class="middle-img-box" v-for="(item, index) in orderList" :key="index" @click="handleorder(index)">
          <img :src="item.img" alt="">
          <div class="img-title">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="exchange">
      <div class="exchange-left" @click="handleall">
        <div>
          <div style="font-weight: bold">兑换订单</div>
          <div style="color: #999999; font-size: 12px;">查看全部订单</div>
        </div>
        <div>
          <img src="../../public/插画1.png" alt="">
        </div>
      </div>
      <div class="exchange-right" @click="handlesearch">
        <div>
          <div style="font-weight: bold">查阅兑换码</div>
          <div style="color: #999999; font-size: 12px;">查看兑换码</div>
        </div>
        <div>
          <img src="../../public/插画2.png" alt="">
        </div>
      </div>
    </div>
    <div class="bottombtn" @click="handleclick">
      <div class="btntitle">
        退出登录
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Footer from "../components/Footer.vue"
import loginApi from "../api/loginApi";
import userinfo from "@/api/userinfo";
export default {
  data() {
    return {
      orderList: [{
        name: "肯德基",
        img: require("../../public/品牌icon (1).png")
      }, {
        name: "麦当劳",
        img: require("../../public/品牌icon (2).png")
      }, {
        name: "必胜客",
        img: require("../../public/品牌icon (3).png")
      }, {
        name: "库迪",
        img: require("../../public/品牌icon (6).png")
      }, {
        name: "奈雪",
        img: require("../../public/品牌icon (5).png")
      }, {
        name: "星咖",
        img: require("../../public/品牌icon (4).png")
      }, {
        name: "瑞幸",
        img: require("../../public/品牌icon (7).png")
      }],
      userid:JSON.parse(localStorage.getItem("USER_INFO")).id,
      userInfo: {},
      fileUrl: [],
      useravatar: localStorage.getItem("USER_INFO") ? JSON.parse(localStorage.getItem("USER_INFO")).avatar : require("../../public/头像.png")
    }
  },
  components: {
    Footer
  },
  methods: {
    handleclick() {
      loginApi.logout(this.userid).then(res => {
        localStorage.removeItem("TOKEN")
        localStorage.removeItem("USER_INFO")
        this.$router.push("/")
        this.$toast("退出成功")
      })
    },
    handleorder(index) {
      if (index == 1) {
        this.$router.push("/exchange")
      } else {
        this.$router.push("/wait")
      }
    },
    handleall() {
      this.$router.push("/order")
    },
    handlesearch() {
      this.$router.push("/queryex")
    },
    handledetail() {
      this.$router.push("/money")
    },

    afterRead(e) {
      this.useravatar = e.content
      let forData = new FormData();
      forData.append("file", e.file)
      userinfo.updateAvatar(forData).then(res => {
        let user = JSON.parse(localStorage.getItem('USER_INFO'))
        user.avatar = e.content
        localStorage.setItem("USER_INFO", JSON.stringify(user))
      })
    }
  },
  // watch: {
  //   fileList(e) {
  //     console.log(e);
  //   }
  // },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("USER_INFO"))
  }
}
</script>
<style lang="less" scoped>
.wode {
  height: 100%;
  background: #F7F7F7;
  position: fixed;
  width: 100%;
  overflow-y: scroll;



  ::-webkit-scrollbar {
    display: none;
  }
}

.wotop {
  width: 100%;
  height: 218px;
  background-image: url("../../public/背景.png");
  background-size: cover;

  .avatar {
    img {
      width: 58px;
      height: 58px;
      border-radius: 50%;
    }

  }

  .left {
    display: flex;
    margin-top: 73px;
    padding-left: 5%;
  }

  .username {
    font-weight: Bold;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .vip {
    display: flex;
    align-items: center;

    img {
      width: 23px;
      height: 23px;
      z-index: 1;
    }

    .viptitle {
      font-size: 10px;
      background-color: #8F8F8F;
      width: 38px;
      height: 17px;
      border-radius: 5px;
      line-height: 17px;
      color: #FFFFFF;
      margin-left: -11px;
      padding-left: 11px;
    }
  }
}

.duihuan {
  .mingxi {
    background-image: url("../../public/标签.png");
    background-size: cover;
    width: 82px;
    height: 28px;
    display: flex;
    line-height: 28px;
  }
}

.bottom1 {
  width: 90%;

  .bimg {
    width: 100%;
    background-image: url("../../public/蒙版组\ 40.png");
    height: 63px;
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  margin: 0 auto;
  margin-top: -63px;
}

.account {
  width: 90%;
  margin: 0 auto;
  height: 89px;
  background: white;
  display: flex;
  margin-top: 5%;
  border-radius: 10px;
  text-align: center;
  align-items: center;

  .account-left {
    flex: 1;
    border-right: 1px solid rgba(112, 112, 112, 0.1);
    ;

  }

  .account-right {
    flex: 1;
  }

  .account-text {
    font-size: 24px;
    font-weight: Bold
  }

  .account-bo {
    font-size: 12px;
    color: #999999;
    margin-top: 10px;
  }
}

.middle {
  width: 90%;
  height: 291px;
  margin: 0 auto;
  background: white;
  margin-top: 12px;
  border-radius: 10px;

  .moddle-title {
    font-size: 15px;
    font-weight: bold;
    margin-left: 3%;
    padding-top: 3%;
  }

  .middle-img {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    .middle-img-box {
      width: 33.3%;
      margin-top: 6%;
    }

    img {
      width: 40px;
      height: 40px;
    }

    .img-title {
      font-size: 12px;
    }
  }
}

.exchange {
  display: flex;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  margin-top: 12px;

  img {
    width: 50px;
    height: 50px;
  }

  .exchange-left {
    width: 41%;
    height: 68px;
    display: flex;
    background: white;
    border-radius: 14px 14px 14px 14px;
    padding: 3%;
    align-items: center;
    justify-content: space-around;
  }

  .exchange-right {
    width: 41%;
    height: 68px;
    display: flex;
    background: white;
    border-radius: 14px 14px 14px 14px;
    padding: 3%;
    align-items: center;
    justify-content: space-around;
  }
}

.bottombtn {
  background-image: url("../../public/按钮.png");
  width: 90%;
  height: 40px;
  margin: 0 auto;
  margin-top: 20px;
  background-size: 100% 100%;

  margin-bottom: 90px;

  // padding-bottom: 23px;
  .btntitle {
    text-align: center;
    line-height: 40px;
    color: white;
  }
}
</style>