<template>
  <div>
    <div class="tab" style="position: fixed;">
      <van-tabs v-model:active="active" line-width="0" title-inactive-color="#999999">
        <van-tab :title="item" v-for="(item, index) in list" :key="index">
          <div class="middle" v-if="index == 0">
            <div class="side">
              <van-sidebar v-model="sideactive">
                <van-sidebar-item :title="data.TAB_TITLE" v-for="(data, dindex) in sidelist" :key="dindex"
                  @click="handleclick(data)" />
              </van-sidebar>
            </div>
            <div class="content" style="width: 100%; height: 100vh;">
              <div class="search">
                <div class="search-input">
                  <form action="/">
                    <!-- <van-search v-model="value" shape="round" placeholder="请输入商品关键词" @change="handlechange" /> -->
                    <van-search v-model="value" shape="round" placeholder="请输入商品关键词" />
                  </form>
                </div>
                <div class="icon">
                  <img src="../../public/图标 (2).png" alt="" style="width: 15px; height:16px ;">
                  <img src="../../public/图标 (3).png" alt="" style="width: 17px; height:16px ;">
                </div>
              </div>
              <div class="number">
                <div class="num1"></div>
                <div class="num2"></div>
                <div class="num3"></div>
                <div class="text">{{ sidelist[sideactive]?.TAB_TITLE }}</div>
                <div class="num3"></div>
                <div class="num2"></div>
                <div class="num1"></div>
              </div>
              <div style="height: 60%; overflow:auto; padding-bottom: 400px;">
                <div class="commcard" v-for="(item, index) in cardlist" :key="item.id">
                  <van-checkbox-group v-model="result" class="card">
                    <div>
                      <img :src="item.IMAGE" alt="">
                    </div>
                    <div>
                      <div class="comm">
                        {{ item.TITLE }}
                      </div>
                      <div class="pbottom">
                        <div class="price">
                          <span style="font-size: 12px;"> ￥{{ item.PRICE }}</span>
                        </div>
                        <!-- <div class="discount">
                          ￥14
                        </div>
                        <div class="cheap">
                          热卖
                        </div> -->
                      </div>
                    </div>
                    <div>
                      <van-checkbox :name="item"></van-checkbox>
                    </div>
                  </van-checkbox-group>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <van-empty description="敬请期待" />
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <div class="submit-btn" v-if="result.length">
      <div class="title">已选择{{ result.length }}件，可任意勾选多个产品生成同一兑换码</div>
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" class="submit" @click="onSubmit">
        去生成
      </van-button>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Footer from "../components/Footer.vue"
import homeApi from "../api/homeApi"
import setmeal from "@/api/setmeal";
export default {
  data() {
    return {
      active: 0,
      sideactive: 0,
      value: "",
      result: [],
      duihuan1: "../../public/icon(3).png",
      list: ["麦当劳", "星巴克", "必胜客", "肯德基", "库迪", "瑞幸", "奈雪"],
      sidelist: [],
      cardlist: [],
      checked: false,
      smIdList: [],
    };
  },
  created() {
    this.onside()
  },
  methods: {
    async onside() {
      let res = await homeApi.getMenu()
      this.sidelist = res
      await this.getPackage({ tab: res[0]?.ID })
      // await this.getPackage()
    },
    async getPackage(data) {
      this.value = ""
      let res = await homeApi.getPackage(data)
      this.cardlist = res
    },
    handleclick(item) {
      this.getPackage({ tab: item.tid })
    },
    onSubmit() {
      this.result.forEach(item => {
        this.smIdList.push(item.ID)
      })
      //  console.log(this.smIdList);
      // this.$router.push({ name: 'detail', params: { value: JSON.stringify(this.result) } })
      this.$router.push(`detail?value=${JSON.stringify(this.smIdList)}`)
    },
    handlechange() {
      setmeal.getcdksearch({ title: this.value }).then(res => {
        this.cardlist = res.records
      })
    }
  },
  components: {
    Footer
  }
}
</script>
<style lang="less" scoped>
::v-deep(.van-tab--active) {
  font-weight: 800;
}

.side {
  margin-top: 20px;
}

.side .van-sidebar {
  border-radius: 0px 20px 20px 0px;
  height: calc(100% - 190px);
  background: #F1F1F1;

  .van-sidebar-item {
    background: #F1F1F1;

    .van-sidebar-item__text {
      font-weight: bold;
      font-size: 14px;
    }
  }

  a {
    padding-left: 7px;
    padding-right: 9px;
    text-align: center;
  }

  a::before {
    background-color: #FFF;
  }

  .van-sidebar-item--select,
  .van-sidebar-item--select:active {
    background: #fff;
    color: #FF4E34;
  }
}

.middle {
  display: flex;
  width: 100%;
}

.search {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .search-input {
    flex: 4;
  }

  .icon {
    flex: 1;
    display: flex;
    justify-content: space-around;
  }
}

.number {
  color: #FF4E34;
  font-size: 14px;
  font-weight: Bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    margin: 0px 9px 0px 9px;
  }

  .num1 {
    width: 3px;
    height: 5px;
    background: #FF4E34;
    opacity: 0.15;
    border-radius: 20px;
  }

  .num2 {
    width: 3px;
    height: 9px;
    background: #FF4E34;
    opacity: 0.52;
    border-radius: 20px;
    margin-right: 3px;
    margin-left: 3px;
  }

  .num3 {
    width: 3px;
    height: 11px;
    background: #FF4E34;
    border-radius: 30px;
  }
}

.commcard {
  border-bottom: 0.8px dashed rgba(#707070, 0.15);
  padding-bottom: 22px;
}

.card {
  height: 100%;
  margin-top: 17px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  img {
    width: 49px;
    height: 50px;
  }

  .comm {
    font-size: 14px;
    font-weight: bold;
  }

  .pbottom {
    display: flex;
    align-items: center;
    width: 120px;
    justify-content: space-between;
    margin-top: 5px;
  }

  .price {
    font-weight: 800;
  }

  .discount {
    font-size: 12px;
    color: #999999;
    text-decoration: line-through;
  }

  .cheap {
    width: 36px;
    height: 17px;
    border: 1px solid #F18E33;
    color: #F18E33;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    text-align: center;
    line-height: 17px;
  }

}

.submit-btn {
  position: fixed;
  bottom: 82px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  background: white;

  .title {
    font-size: 11px;
    line-height: 40px;
    color: #ff6034
  }

  .submit {
    width: 100px;
    height: 40px;
    border-radius: 20px;
  }
}
</style>