<template>
    <div>
        <!-- 已关闭 -->
        <div class="middle" style="margin-top: 15px;" v-for="(item, index) in item3" :key="index">
            <div class="tip">
                <span class="iconfont" style="margin-right: 5px;"> &#xe628;</span>
                <div class="fz14">已关闭</div>
            </div>
            <div class="http">
                <div class="fz12 line">链接：{{ item.DOMAIN_NAME }}{{ item.CODE }}</div>
                <div style="color:#666666" @click="copyText(item.CODE, item.DOMAIN_NAME)">
                    <span class="iconfont fz12"> &#xe603;</span>
                    <span class="fz12">复制</span>
                </div>
            </div>
            <div class="redeem">
                <div class="fz12 line">兑换码：{{ item.CODE }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { copy } from 'clipboard';
export default {
    data() {
        return {
            list: []
        }
    },
    created() {
    },
    props: ["item3", "domainName"],
    methods: {
        copyText(value1, value2) {
            copy('兑换码：' + value1 + " " + "链接：" + value2 + value1)
            Toast('已复制到剪贴板!');
        }
    }
}
</script>
<style lang="less" scoped>
.middle {
    margin-top: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    background: white;
    margin: 0 auto;
    padding: 15px;
    box-sizing: border-box;

    .tip {
        display: flex;
        align-items: center;
    }

    .http {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        color: #999999;
    }

    .redeem {
        margin-top: 8px;
        color: #999999;
    }

    .floorbto {
        margin-top: 20px;
        color: #666666;
        background: white;

        .timer {
            padding-bottom: 12px;
            padding-top: 12px;
        }

        .account {
            padding-top: 12px;
        }

        .timer,
        .account {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #F1F1F1;
        }
    }
}
</style>