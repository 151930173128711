// /mdl/common/search/order/{order}
import { moduleRequest } from '../util/request'

const request = moduleRequest(`/mdl`)
/**
 * home
 *
 * @author yubaoshan
 * @date 2024-09-22 22:33:20
 */
export default {
    //生成兑换码
    getorderinfo(data) {
        return request('/getOrderByOrderId', data, 'post')
    },
}