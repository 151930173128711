<template>
    <div class="order">
        <img src="../../public/10.png" alt="" class="limg">
        <div class="content">
            <div>
                <div class="top ">
                    <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
                    <p style=" float: left;">订单</p>
                    <p></p>
                </div>
                <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div class="middle" v-for="(item, index) in list" :key="index" @click="handleclick(item)">
                        <div class="tip">
                            <img src="../../public/麦当劳.png" alt=""
                                style="width: 72px; height: 27px; position: absolute;top: 0px;right: 0;">
                            <div class="number">
                                <div class="ordernum">编号：<span>{{ item.ID }}</span></div>
                                <div>
                                    <span class="iconfont" @click.stop="handlecopy(item.ID)"> &#xe603;</span>
                                </div>
                            </div>
                            <div class="itemsbox">
                                <div class="items" v-for="(data, index) in item.meal" :key="index">
                                    <div class="itemtop">
                                        <div class="title">
                                            选项{{ index + 1 }}
                                        </div>
                                        <img :src="data.IMAGE" alt="">
                                    </div>
                                    <div class="fz14" style="margin: 5px;">
                                        {{ data.TITLE }}
                                    </div>
                                    <div class="fz14" style="margin: 5px;">
                                        <span style="font-weight: 700;">￥{{ data.PRICE }}</span>
                                    </div>
                                </div>
                            </div>
                            <div style="padding: 15px;">
                                <div class="info">
                                    <div class="fz14">订单信息</div>
                                    <div class="inright">
                                        <img src="../../public/1.png" alt="" style="width: 11px; height: 10px;">
                                        <div class="fz12">限价</div>
                                        <div
                                            style="width: 1px; height: 9px;background: #FCC1B7FF;margin-left: 2px; margin-right: 2px;">
                                        </div>
                                        <div class="fz12">{{ item.LIMIT_PRICE ? item.LIMIT_PRICE : '无' }}</div>
                                    </div>
                                </div>
                                <div class="timer fz12 mt10">
                                    <div>生成时间</div>
                                    <div>{{ item.CREATED_TIME }}</div>
                                </div>
                                <div class="timer fz12 mt10">
                                    <div>有效期至</div>
                                    <div>{{ item.END_TIME }}</div>
                                </div>
                            </div>
                            <div class="exchange1 fz12">
                                本单共生成<span>{{ item.COUNT }}</span>个链接，共有<span>{{
                                    item.meal.length }}</span>件产品，用户可择一兑换
                            </div>
                        </div>
                        <div class="bottom">
                            <div class="leftimg"></div>
                            <div class="botimg"></div>
                            <div class="rightimg"></div>
                        </div>
                        <div class="floor justify-content">
                            <div class="justify-content">
                                <span class="iconfont fo"> &#xe8c5;</span>
                                <div class="fz13">待使用</div>
                                <div class="fw fo">{{ item.arr[0][0].weijia }}</div>
                            </div>
                            <div class="justify-content">
                                <span class="iconfont fg">&#xe8e4;</span>
                                <div class="fz13">已出餐</div>
                                <div class="fw fg">{{ item.arr[2][0].weijia }}</div>
                            </div>
                            <div class="justify-content">
                                <span class="iconfont"> &#xe628;</span>
                                <div class="fz13">已关闭</div>
                                <div class="fw">{{ item.arr[1][0].weijia }}</div>
                            </div>
                        </div>
                    </div>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
import getmeal from '@/api/getmeal'
import { copy } from 'clipboard'
import { Toast } from "vant"
export default {
    data() {
        return {
            list: [],
            page: 1,
            size: 10,
            userId: JSON.parse(localStorage.getItem("USER_INFO")).id,
            loading: false,
            finished: false,
            total: 0
        }
    },
    methods: {
        handleclick(item) {
            this.$router.push({
                path: `/orderdetail?item=${encodeURIComponent(JSON.stringify(item))}`
            })
        },
        handleList() {
            getmeal.getmenu({
                page: this.page,
                size: this.size,
                userId: this.userId
            }).then(res => {
                this.list = res.list
                this.total = res.total.weijia
            })
        },
        handlecopy(item) {
            copy(item)
            Toast('已复制到剪贴板!');
        },
        onLoad() {
            // 加载状态结束
            if (this.list.length >= this.total) {
                this.loading = false; // 关闭加载状态
                this.finished = true;
            } else {
                this.page = this.page + 1
                this.size = this.size + 10
                this.handleList()
            }

            // getmeal.getmenu({
            //     page: this.page,
            //     size: this.size,
            //     userId: this.userId
            // }).then(res => {
            //     this.list.push(res.list)
            //     this.loading = false;

            // })
        }
    },
    mounted() {
        this.handleList()
    }
}
</script>
<style lang="less" scoped>
.order {
    height: 100%;
    background: #F1F1F1FF;
    position: fixed;
    width: 100%;
    overflow-y: scroll;

    .limg {
        width: 311px;
        float: right;
        position: relative;
    }

    .fo {
        color: #FE583C;
    }

    .fg {
        color: #20B759;
    }

    .content {
        margin-top: 30px;
        position: absolute;
        width: 100%;
        // font-family: SourceHanSansCN-Medium;
        z-index: 100;
        padding-bottom: 20px;

        .bottom {
            width: 90%;
            background: white;
            height: 21px;
            margin: 0 auto;
            position: relative;

            .leftimg {
                width: 11px;
                height: 18px;
                background-color: #F1F1F1FF;
                border-radius: 0 50px 50px 0;
                float: left;
            }

            .botimg {
                width: 90%;
                height: 1;
                border-top: 1px dashed #F1F1F1FF;
                position: absolute;
                top: 9px;
                left: 5%;
            }

            .rightimg {
                width: 11px;
                height: 18px;
                background-color: #F1F1F1FF;
                border-radius: 50px 0 0 50px;
                float: right;
            }
        }

        .floor {
            width: 90%;
            height: 40px;
            margin: 0 auto;
            border-radius: 0 0 10px 10px;
            background: white;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
            color: #666666;
        }

        .top {
            font-weight: bold;
            display: flex;
            width: 90%;
            margin: auto;
            justify-content: space-between;
        }

        .tip {
            background: #ffffff;
            width: 90%;
            border-radius: 10px 10px 0 0;
            margin: 0 auto;
            margin-top: 15px;
            padding-bottom: 10px;
            position: relative;

            .exchange1 {
                background: #FFF2F0;
                border-radius: 13px;
                height: 27px;
                width: 95%;
                margin: 0 auto;
                color: #FE583C;
                text-align: center;
                line-height: 27px;

                span {
                    font-weight: 700;
                }
            }

            .exchange2 {
                background: #F1F1F1;
                border-radius: 13px;
                height: 27px;
                width: 95%;
                margin: 0 auto;
                color: #999999;
                text-align: center;
                line-height: 27px;

                span {
                    color: #FE583C;
                    font-weight: 700;
                }
            }

            .info {
                display: flex;
                justify-content: space-between;

                .inright {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    border-radius: 5px;
                    border: 1px solid #FCC1B7;
                    // width: 73px;
                    padding: 2px;
                    height: 18px;
                    color: #FF4645FF;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    padding-right: 5px;
                    box-sizing: border-box;
                }
            }

            .timer {
                color: #666666;
                display: flex;
                justify-content: space-between;
            }

            .ordernum {
                font-size: 14px;
                font-weight: 500;

            }

            .itemsbox {
                display: flex;
                overflow: auto;
            }

            .items {
                padding: 18px 0 0 12px;

                .itemtop {
                    width: 84px;
                    height: 84px;
                    background: #F6F6F6FF;
                    border-radius: 10px 10px 10px 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;

                    .title {
                        width: 42px;
                        height: 18px;
                        background: #FFF2EF;
                        color: #FE5133;
                        font-size: 11px;
                        text-align: center;
                        position: absolute;
                        top: 0;
                        right: 0;
                        border-radius: 2px 10px 2px 10px;
                    }

                    img {
                        width: 52px;
                        height: 55px;
                    }
                }

            }

            .number {
                display: flex;
                align-items: center;
                padding-left: 10px;
                padding-top: 12px;
                width: calc(100% - 100px);
                justify-content: space-between;
            }
        }
    }
}
</style>