import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from "../views/Login.vue"
import Wode from "../views/Wode.vue"
import Exchange from "../views/Exchange.vue"
import Detail from "../views/Detail.vue"
import Order from '@/views/Order.vue'
import OrderDetail from '@/views/OrderDetail.vue'
import Queryex from '@/views/Queryex.vue'
import Money from '@/views/Money.vue'
import Wait from '@/views/Wait.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/wode',
    name: 'wode',
    component: Wode
  }, {
    path: '/exchange',
    name: 'exchange',
    component:Exchange
  },{
    path: '/detail',
    name: 'detail',
    component:Detail
  },{
    path:"/order",
    name:"order",
    component:Order
  },{
    path:"/orderdetail",
    name:"orderdetail",
    component:OrderDetail
  },{
    path:"/queryex",
    name:"queryex",
    component:Queryex
  },{
    path:"/money",
    name:"money",
    component:Money
  },{
    path:"/wait",
    name:"wait",
    component:Wait
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
