var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wode"},[_c('div',{staticClass:"wotop"},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticClass:"left"},[_c('img',{staticStyle:{"width":"80px","height":"80px","border-radius":"50%"},attrs:{"src":"https://tse4-mm.cn.bing.net/th/id/OIP-C.Ekyqq_T0BDXvef1bfI2qDQHaHa?rs=1&pid=ImgDetMain","alt":""}}),_c('div',{staticClass:"username",staticStyle:{"margin-left":"15px"}},[_c('div',[_vm._v(" "+_vm._s(_vm.userInfo.name)+" ")]),_vm._m(0)])]),_c('div',{staticClass:"right",staticStyle:{"margin-top":"73px"}},[_vm._m(1),_c('div',{staticClass:"duihuan",on:{"click":_vm.handledetail}},[_vm._m(2)])])])]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"moddle-title"},[_vm._v(" 我的订单 ")]),_c('div',{staticClass:"middle-img"},_vm._l((_vm.orderList),function(item,index){return _c('div',{key:index,staticClass:"middle-img-box",on:{"click":function($event){return _vm.handleorder(index)}}},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('div',{staticClass:"img-title"},[_vm._v(_vm._s(item.name))])])}),0)]),_c('div',{staticClass:"exchange"},[_c('div',{staticClass:"exchange-left",on:{"click":_vm.handleall}},[_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"exchange-right",on:{"click":_vm.handlesearch}},[_vm._m(7),_vm._m(8)])]),_c('div',{staticClass:"bottombtn",on:{"click":_vm.handleclick}},[_c('div',{staticClass:"btntitle"},[_vm._v(" 退出登录 ")])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"vip"},[_c('img',{attrs:{"src":require("../../public/VIP.png"),"alt":""}}),_c('div',{staticClass:"viptitle"},[_vm._v("未开通")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","padding-left":"15px","margin-bottom":"7px","align-items":"center"}},[_c('img',{staticStyle:{"width":"16.45px","height":"15.54px"},attrs:{"src":require("../../public/设置.png"),"alt":""}}),_c('div',{staticStyle:{"margin-left":"4px"}},[_vm._v("设置")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mingxi"},[_c('div',{staticStyle:{"color":"white","font-size":"12px","margin-left":"15%"}},[_vm._v("资金明细")]),_c('div',{staticStyle:{"margin-left":"10%"}},[_c('img',{staticStyle:{"width":"5px","height":"8px"},attrs:{"src":require("../../public/前进.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom1"},[_c('div',{staticClass:"bimg",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{staticStyle:{"display":"flex","margin-left":"8%","margin-top":"15px","justify-content":"center"}},[_c('img',{staticStyle:{"width":"26px","height":"24px"},attrs:{"src":require("../../public/会员.png"),"alt":""}}),_c('div',{staticStyle:{"color":"white","font-size":"15px","margin-left":"5px"}},[_vm._v("会员中心")])]),_c('div',{staticStyle:{"color":"#F7DABB","font-size":"12px","margin-right":"8%","margin-top":"18px"}},[_vm._v(" 开通会员，尊享更多权益> ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account"},[_c('div',{staticClass:"account-left"},[_c('div',{staticClass:"account-text"},[_vm._v("0")]),_c('div',{staticClass:"account-bo"},[_vm._v("可用余额(元)")])]),_c('div',{staticClass:"account-right"},[_c('div',{staticClass:"account-text"},[_vm._v("0")]),_c('div',{staticClass:"account-bo"},[_vm._v("账户加款")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v("兑换订单")]),_c('div',{staticStyle:{"color":"#999999","font-size":"12px"}},[_vm._v("查看全部订单")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../public/插画1.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v("查阅兑换码")]),_c('div',{staticStyle:{"color":"#999999","font-size":"12px"}},[_vm._v("查看兑换码")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"src":require("../../public/插画2.png"),"alt":""}})])
}]

export { render, staticRenderFns }