<template>
      <div class="bottom">
        <van-tabbar route active-color="#FF4E34">
          <van-tabbar-item replace to="/home">
            <span>点餐兑换</span>
            <template #icon="props">
              <img :src="props.active ? icon.active : icon.inactive" />
            </template>
          </van-tabbar-item>
          <van-tabbar-item replace to="/wode">
            <span>我的</span>
            <template #icon="props">
              <img :src="props.active ? icon1.active : icon1.inactive" />
            </template>
          </van-tabbar-item>
        </van-tabbar>
      </div>
</template>

<script>
export default {
  data() {
    return {
      boactive: 0,
      icon: {
        active: require("../../public/icon(2).png"),
        inactive: require("../../public/icon(3).png"),
      },
      icon1: {
        active: require("../../public/icon(4).png"),
        inactive: require("../../public/icon(1).png"),
      },
    }
  },
  methods: {
  },
  props: {
  }
}
</script>

<style scoped lang="less">
.bottom {
  .van-hairline--top-bottom {
    height: 82px;
    line-height: 82px
  }

  img {
    width: 18px;
    height: 18px;
    margin-bottom: 6px
  }

  .van-tabbar-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

  }
}
</style>
