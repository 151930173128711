import router from './router'
import store from './store'//删除token的方法在vuex中
import tool from "@/util/tool"

//白名单：就是指不需要登录就可以直接进入的路由地址名单
const whiteList = ['/']

//to：进入到哪个路由去
//from：从哪个路由离开
//next：进入下一步
router.beforeEach(async (to, from, next) => {
    //console.log('to.meta:', to.meta);
    if (to.meta) {
        // window.document.title = to.meta.title == undefined ? '' : to.meta.title
        document.title = "麦麦";
        //以下判断和操作，大家根据实际的项目需求去做，这里做参考即可。
        try {
            // 获取token，判断用户是否登录
            const hasToken = tool.data.get('TOKEN');
            /* 如果有token*/
            if (hasToken) {
                next()
            }
            /* 没有token*/
            else {
                // 如果没有登录且路由地址在白名单当中，则直接进入该路由地址
                if (whiteList.indexOf(to.path) !== -1) {
                    next()
                }
                // 如果没有登录且路由地址不在白名单当中，则重定向到登录页去
                else {
                    next(`/`)
                }
            }
        }
        catch (error) {
            // 删除token，然后跳转到登录页面重新登录。
            //   await store.dispatch('user/resetToken');
            //   Toast(error || 'Has Error');
            next(`/`)
        }
    }
});
