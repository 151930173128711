<template>
    <div>
        <van-empty description="敬请期待" />
    </div>
</template>
<script>
export default {
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>