<template>
    <div class="change">
        <van-tabs v-model:active="active" line-width="13px" color="#FF4E34" title-inactive-color="#999999"
            @click="onClickTab">
            <van-tab :title="item.title" v-for="(item, index) in list" :key="index">
                <div class="top">
                    <form action="/">
                        <van-search v-model="value" placeholder="请输入搜索关键词" show-action @search="onSearch" class="sel">
                            <template #label>
                                <div class="opt">
                                    <van-dropdown-menu>
                                        <van-dropdown-item v-model="value1" :options="option1" />
                                    </van-dropdown-menu>
                                </div>
                            </template>
                            <template #action>
                                <div @click="onSearch">搜索</div>
                            </template>
                        </van-search>
                    </form>
                </div>
                <div class="list" v-if="orderList.length" v-for="(item, index) in orderList" :key="index">
                    <div class="tip">
                        <div class="tip_left">
                            <div class="icon">打包</div>
                            <div class="title">{{ item.STORE_NAME }}</div>
                        </div>
                        <div class="fondover">{{ statusList[item.ODER_STATUS] ? statusList[item.ODER_STATUS] : '未支付' }}
                        </div>
                    </div>
                    <div class="word">
                        订单编号：
                        <span>{{ item.OPEN_ID }}</span>
                    </div>
                    <div class="word">
                        下单时间：
                        <span>{{ item.USE_TIME ? timestampToTime(item.USE_TIME) : '未下单' }}</span>
                    </div>
                    <div class="word word_last" style="padding-bottom: 10px;">
                        <div style="margin-right: 10px;">
                            <span>取餐码：</span>
                            <span>{{ item.CODE ? item.CODE : "暂无" }}</span>
                        </div>
                        <span class="iconfont" @click.stop="handlecopy(item.CODE)"> &#xe603;</span>
                    </div>
                    <div class="card_bottom">
                        <div class="left">
                            <span class="left_word">实付款</span>
                            <span class="left_price">￥{{ item.PRICE_NUM }}</span>
                        </div>
                        <div class="right">
                            <van-button type="primary" size="small" class="contact">联系客服</van-button>
                            <van-button type="primary" size="small" class="detail">查看详情</van-button>
                        </div>
                    </div>
                </div>
                <van-empty description="暂无订单" v-if="!orderList.length" />
            </van-tab>
        </van-tabs>
    </div>
</template>
<script>
import getorder from '@/api/getorder';
import { Toast } from "vant"
import loginApi from '@/api/setmeal';
import { timestampToTime } from "@/util/clipboard"
export default {
    created() {
        this.getmenuList()
    },
    data() {
        return {
            active: 0,
            value: "",
            value1: 0,
            option1: [
                { text: '订单单号', value: 0 }
            ],
            list: [{
                title: "全部"
            }, {
                title: "待出餐"
            }, {
                title: "已出餐"
            }, {
                title: "已关闭"
            }],
            statusList: {
                0: "待出餐",
                1: "已出餐",
                2: "已关闭"
            },
            orderList: []
        }
    },
    methods: {
        onClickTab(e) {
            if (e === 0) {
                loginApi.getorderList().then(res => {
                    this.orderList = res
                })
            } else if (e === 1) {
                loginApi.getorderList().then(res => {
                    this.orderList = res.filter(item => item.ODER_STATUS == 0)
                })
            } else if (e === 2) {
                loginApi.getorderList().then(res => {
                    this.orderList = res.filter(item => item.ODER_STATUS == 1)
                })
            } else if (e === 3) {
                loginApi.getorderList().then(res => {
                    this.orderList = res.filter(item => item.ODER_STATUS == 2)
                })
            }
        },
        onSearch(e) {
            this.orderList = []
            if (this.value) {
                if (this.value1 == 0) {
                    getorder.getorderinfo({ orderId: this.value }).then(res => {
                        if (res) {
                            this.orderList.push(res[0]);
                            console.log(res);
                        }
                    })
                }
            }
        },
        async getmenuList() {

        },
        handlecopy(item) {
            navigator.clipboard.writeText(item);
            Toast('已复制到剪贴板!');
        }
    },
    created() {
        loginApi.getorderList().then(res => {
            this.orderList = res
        })
    }
}
</script>
<style lang="less" scoped>
::v-deep(.van-tab--active) {
    font-weight: 800;
}

.change {
    width: 100%;
    height: 100%;
    background-color: #F7F7F7;
    position: fixed;
    overflow-y: scroll;

    .top {
        .sel {
            padding-top: 20px;
            padding-bottom: 20px;

            ::v-deep(.van-search__content--square) {
                border-radius: 15px;
            }
        }

        .opt {
            align-items: center;
            font-size: 12px;
            margin-top: 6px;

            ::v-deep(.van-dropdown-menu__bar) {
                box-shadow: none;
                background-color: #F7F8FA;
                height: 100%;
                padding-right: 10px;
                border-right: 1px solid #C7C7C7;

                .van-ellipsis {
                    color: #666666;
                }
            }
        }
    }
}

.list {
    width: 90%;
    background-color: white;
    margin: 0 auto;
    border-radius: 12px 12px 12px 12px;
    margin-top: 13px;
    box-sizing: border-box;
    padding: 12px;

    .tip {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .tip_left {
            display: flex;
            align-items: center;
        }

        .icon {
            margin-right: 5px;
            border-radius: 8px 8px 0px 8px;
            background: #FF4F34;
            width: 31px;
            height: 15px;
            font-size: 9px;
            color: #FFFFFF;
            text-align: center;
            line-height: 16px;
        }

        .title {
            font-size: 15px;
            font-weight: bold;
        }

        .fondover {
            color: #2BB921;
            font-size: 14px;
        }
    }

    .word {
        color: #999999;
        font-size: 14px;
        margin-top: 8px;

    }

    .word_last {
        display: flex;
        position: relative;
        align-items: center;

        .addt {
            width: 9px;
            height: 9px;
            border: 1px solid #FF4E34;
            color: #FF4E34;
            text-align: center;
            line-height: 9px;
            position: relative;
            top: 6px;
            background: white;
            z-index: 1;
            font-size: 10px;
        }

        .addb {
            position: absolute;
            top: 4px;
            left: 5px;
            width: 9px;
            height: 9px;
            border: 1px solid #FF4E34;
        }
    }
}

.card_bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    align-items: center;
    border-top: 1px solid rgba(153, 153, 153, 0.1);

    .left {
        display: flex;
        align-items: center;

        .left_word {
            color: #999999;
            font-size: 12px;
            margin-right: 3px;
        }

        .left_price {
            color: #FF4E34;
        }
    }

    .right {
        button {
            width: 85px;
            height: 35px;
            border-radius: 18px 18px 18px 18px;
        }

        .contact {
            border: 1px solid #D8D8D8;
            background-color: white;
            color: black;
            line-height: 35px;
            margin-right: 7px;
        }

        .detail {
            border: 1px solid #FF4E34;
            background: #FFFFFF;
            color: #FF4E34;
            line-height: 35px;
        }
    }
}
</style>