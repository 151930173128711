import { moduleRequest } from '../util/request'
// /mdl/mobile/cdk/{cdkey}
const request = moduleRequest(`/mdl/mobile/`)
/**
 * home
 *
 * @author yubaoshan
 * @date 2024-09-22 22:33:20
 */
export default {
    //生成兑换码
    generate(data) {
        return request('menu/cdkey', data, 'post')
    },
    //兑换码状态查询
    condition(data) {
        return request('cdk', data, 'geto')
    },
    //下架兑换码
    down(data) {
        console.log(data);
        return request('cdk/sold', data, 'deleted')
    },
    //批量下架
    downBatch(data) {
        console.log(data);
        return request('cdk/solds', data, 'delete')
    },
    //自定义域名
    domainmy(data) {
        return request('updata/domain', data, 'post')
    },
}