import { moduleRequest } from '../util/request'

const request = moduleRequest(`/mdl/mobile/`)
/**
 * home
 *
 * @author yubaoshan
 * @date 2024-09-22 22:33:20
 */
export default {
    //兑换码列表
    getmenu(data) {
        return request('cdkmenu/list', data, 'get')
    },
    //兑换码详情
    getmenudetail(data) {
        return request(`cdk/list`, data, 'getc')
    }
}