<template>
    <div>
        <!-- 已使用 -->
        <div class="middle" style="margin-top: 15px;" v-for="(item, index) in item2" :key="index">
            <div class="tip">
                <span class="iconfont fg" style="margin-right: 5px;">&#xe8e4;</span>
                <div class="fz14">已使用</div>
            </div>
            <div class="http">
                <div class="fz12 line">链接：{{ item.DOMAIN_NAME }}{{ item.CODE }}</div>
                <div style="color:#666666" @click="copyText(item.CODE, item.DOMAIN_NAME)">
                    <span class="iconfont fz12"> &#xe603;</span>
                    <span class="fz12">复制</span>
                </div>
            </div>
            <div class="redeem">
                <div class="fz12 line">兑换码：{{ item.CODE }}</div>
            </div>
            <div class="floorbto fz14">
                <div class="timer">
                    <div>使用时间</div>
                    <div>{{ timer }}</div>
                </div>
                <div class="account">
                    <div>使用账户</div>
                    <div>18606019700</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { copy } from 'clipboard';
export default {
    data() {
        return {
            list: [],
            timer:""
        }
    },
    created() {
        this.timer = this.list.USE_TIME?this.timestampToTime(this.list.USE_TIME):"未使用"
    },
    mounted() {

    },
    methods: {
        copyText(value1, value2) {
            copy('兑换码：' + value1 + " " + "链接：" + value2 + value1)
            Toast('已复制到剪贴板!');
        },
        //转时间戳
        timestampToTime(timestamp) {
            const date = new Date(Number(timestamp));

            // 获取日期和时间的各个部分
            const year = date.getFullYear();
            const month = `0${date.getMonth() + 1}`.slice(-2); // 月份是从0开始的，所以加1并补零
            const day = `0${date.getDate()}`.slice(-2);
            const hours = `0${date.getHours()}`.slice(-2);
            const minutes = `0${date.getMinutes()}`.slice(-2);
            const seconds = `0${date.getSeconds()}`.slice(-2);

            // 返回格式化的日期字符串 "YYYY-MM-DD HH:mm:ss"
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        }
    },
    props: ["item2", "domainName"]
}
</script>
<style lang="less" scoped>
.fg {
    color: #20B759;
}

.middle {
    margin-top: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    background: white;
    margin: 0 auto;
    padding: 15px;
    box-sizing: border-box;

    .tip {
        display: flex;
        align-items: center;
    }

    .http {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        color: #999999;
    }

    .redeem {
        margin-top: 8px;
        color: #999999;
    }

    .floorbto {
        margin-top: 20px;
        color: #666666;
        background: white;

        .timer {
            padding-bottom: 12px;
            padding-top: 12px;
        }

        .account {
            padding-top: 12px;
        }

        .timer,
        .account {
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #F1F1F1;
        }
    }
}
</style>