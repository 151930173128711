<template>
    <div>
        <div class="login">
            <div class="top">
                <img :src="icon" alt="">
            </div>
            <div style="margin-top: -50%;">
                <div class="text">
                    <div>
                        <input type="text" placeholder="输入账号" v-model="username">
                    </div>
                    <div>
                        <input type="password" placeholder="输入密码" v-model="password">
                    </div>
                </div>
            </div>
        </div>
        <div class="bottombtn" @click="login">
            <div class="btntitle">
                登录
            </div>
        </div>
        <div style="font-size: 14px;" class="notice">
            <van-checkbox v-model="checked" />
            <div style="color: #999999;margin-left: 3%"> 我已阅读并同意</div>
            <div style="color:#27ADFF ;">《用户协议》</div>
            <div style="color:#27ADFF ;">《隐私政策》</div>
        </div>
    </div>
</template>
<script>
import loginApi from "../api/loginApi";
import smCrypto from '../util/smCrypto';
import tool from "../util/tool";
import md5 from "js-md5";
export default {
    data() {
        return {
            icon: require("../../public/login.png"),
            checked: false,
            username: "",
            password: ""
        }
    },
    methods: {
        async login() {
            if (this.username == "" || this.password == "") {
                this.$toast("请输入账号或密码");
            } else {
                if (!this.checked) {
                    this.$toast("请勾选协议");
                    return;
                }
                const loginData = {
                    name: this.username,
                    // 密码进行SM2加密，传输过程中看到的只有密文，后端存储使用hash
                    pwd: md5(this.password)
                }
                const loginToken = await loginApi.login(loginData)
                tool.data.set('TOKEN', loginToken.token)
                // 获取登录的用户信息
                const loginUser = await loginApi.getLoginUser(loginToken.uid)
                tool.data.set('USER_INFO', loginUser)
                this.$toast("登录成功");
                this.$router.push("/home");
            }

        },
    }
}
</script>
<style lang="less" scoped>
.top {
    width: 100%;
    height: 350px;
    background: linear-gradient(#ffe2dd, #ffdbd8);
    border-radius: 0 0 20% 20%;

    img {
        margin-top: 14%;
        margin-left: -10%;
    }
}

.text {
    width: 80%;
    margin: auto;

    input {
        width: 100%;
        height: 40px;
        margin-top: 20px;
        border-radius: 20px;
        border: none;
        outline: none;
        padding-left: 5%;
    }
}

.bottombtn {
    background-image: url("../../public/按钮.png");
    width: 90%;
    height: 45px;
    background-size: 100% 100%;
    position: fixed;
    bottom: 30%;
    left: 5%;

    .btntitle {
        text-align: center;
        line-height: 45px;
        color: white;
    }
}

.notice {
    position: fixed;
    bottom: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>