import { moduleRequest } from '../util/request'

const request = moduleRequest(`/mdl/mobile/`)
/**
 * home
 *
 * @author yubaoshan
 * @date 2024-09-22 22:33:20
 */
export default {
    //请求side菜单
    getMenu(data) {
        return request('menu/list', data, 'get')
    },
    //请求套餐
    getPackage(data) {
        return request('product/list', data, 'getp')
    },
}