<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "@/util/font_4481958_1m2g5z7s7cqh/iconfont.css"
export default {

  data() {
    return {
    }
  },
}
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  max-width: 450px;
}

.line {
  width: 200px;
  /* 请根据实际需求设定宽度 */
  white-space: nowrap;
  /* 强制文本在一行内显示 */
  overflow: hidden;
  /* 隐藏超出容器的内容 */
  text-overflow: ellipsis;
  /* 当文本溢出时显示省略号 */
}

.fz14 {
  font-size: 14px;
}

.fz12 {
  font-size: 12px;
}

.fz13 {
  font-size: 13px;
}

.fz15 {
  font-size: 15px;
}

.mt10 {
  margin-top: 10px;
}

.ma {
  margin: 0 auto;
}

.line {
  text-decoration: line-through;
}

::-webkit-scrollbar {
  display: none;
}

.fw {
  font-weight: bold;
}

.justify-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
