<template>
    <div class="detail">
        <img src="../../public/10.png" alt="" class="limg">
        <div class="content">
            <div class="top">
                <van-icon name="arrow-left" size="20px" @click="$router.go(-1)" />
                <p>订单详情</p>
                <div></div>
            </div>
            <div>
                <div class="title ma">
                    <div class="title-left fz14">
                        <span>
                            已选产品<span class="fz13" style="opacity: 80%;">（用户只可择其一兑换）</span>
                        </span>
                    </div>
                    <div class="title-right fz12">
                        麦当劳
                    </div>
                </div>
                <div class="items-list">
                    <div class="items" v-for="item in itemlist.meal">
                        <div style="display: flex;width: 200px; ">
                            <div>
                                <img :src="item.IMAGE" alt="" style="width: 60px;height: 70px;margin-right: 10px;">
                            </div>
                            <div>
                                <div class="fz14 ellipsis">{{ item.TITLE }}</div>
                                <div style="color:#999999">
                                    <div class="fz12 ellipsis" style="margin-top: 4px;">{{ item.discount }}</div>
                                    <div class="fz12 ellipsis" style="margin-top: 4px;">{{ item.positivePrice }}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="fw">￥{{ item.PRICE }}</div>
                            <!-- <div class="fz12" style="color:#999999">原价<span class="line">￥30</span></div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="centre">
                <van-field name="checkbox" input-align="right" class="mar1">
                    <template #label>
                        <div class="text">自定义域名</div>
                    </template>
                    <template #input>
                        <div style="display: flex;align-items: center;">
                            <span class="fz14 tes" style="color: #666666;" v-if="isshow">{{ itemlist.DOMAIN_NAME
                                }}</span>
                            <input type="text" v-model="domain" class="textinput" placeholder="请输入自定义域名"
                                @keyup.enter="handleonblur" @blur="handleonblur" v-else>
                            <img src="../../public/编辑.png" alt="" style="width: 13px; height: 13px;margin-left: 8px;"
                                @click="handleupdate">
                        </div>
                    </template>
                </van-field>
                <van-field name="switch" input-align="right" class="mar2">
                    <template #label>
                        <div class="text">短信通知</div>
                    </template>
                    <template #input>
                        <van-switch v-model="checked" size="20px" disabled inactive-color="#F7F7F7"
                            active-color="#FF4E34" />
                    </template>
                </van-field>
            </div>
            <div class="classify">
                <van-tabs v-model="active" line-width="13px" color="#FF4E34" title-inactive-color="#999999">
                    <van-tab v-for="(item, index) in list" :key="index" :title="item.title">
                        <Waiting v-if="active == 1 || active == 0" :item1=item1 :domainName="itemlist.domainName" />
                        <Used v-if="active == 2 || active == 0" :item2=item2 :domainName="itemlist.domainName" />
                        <Close v-if="active == 3 || active == 0" :item3=item3 :domainName="itemlist.domainName" />
                    </van-tab>
                </van-tabs>
            </div>
            <div class="floor">
                <div class="fr">
                    <img src="../../public/shopping.png" alt="" style="width: 20px; height: 20px;">
                    <div class="fz15" style="color: #999999;margin: 0 10px 0 10px;" @click="handleonClick">
                        全部下架
                    </div>
                    <van-button plain round type="primary" color="#FF5633" style="height: 35px;"
                        @click="handleonexport">一键导出</van-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Toast } from 'vant';
import { Dialog } from 'vant';
import getmeal from '@/api/getmeal';
import Waiting from '@/components/status/Waiting.vue';
import Close from '@/components/status/Close.vue';
import Used from '@/components/status/Used.vue';
import change from '@/api/change';
export default {
    data() {
        return {
            checked: false,
            active: 0,
            list: [{
                title: "全部",
                id: 0,
            }, {
                title: "待使用",
                id: 1,
            }, {
                title: "已使用",
                id: 2,
            }, {
                title: "已关闭",
                id: 3,
            }],
            itemlist: [],
            item1: [],
            item2: [],
            item3: [],
            xiajialist: [],
            isshow: true,
            domain: "",
            text: []
        }
    },
    components: {
        Used,
        Waiting,
        Close
    },
    methods: {
        handle() {
            getmeal.getmenudetail({ cdkId: this.itemlist.ID }).then(res => {
                console.log(res.list);
                this.item1 = res.list.filter(item => {
                    return item.CODE_STATUS == 0
                })
                this.item2 = res.list.filter(item => {
                    return item.CODE_STATUS == 2
                })
                this.item3 = res.list.filter(item => {
                    return item.CODE_STATUS == 1
                })
            })
        },
        handleonClick() {
            Dialog.confirm({
                title: '提示',
                message: '确定全部下架吗',
            })
                .then(() => {
                    this.xiajialist = []
                    this.item1.filter(res => {
                        this.xiajialist.push(res.cid)
                    })
                    change.downBatch(this.xiajialist).then(res => {
                        this.handle()
                        Toast('已全部下架!');
                    })
                })
                .catch(() => {
                    // on cancel
                });

        },
        handleupdate() {
            this.isshow = false
            if (this.domain == "") {
                this.domain = this.itemlist.domainName
            }
        },
        handleonblur() {
            change.domainmy({ domainName: this.domain, cdkId: this.itemlist.cdkId }).then(res => {
                this.itemlist.domainName = this.domain
            })
            this.isshow = true
        },
        downloadText(fileName, text) {
            const url = window.URL || window.webkitURL || window;
            const blob = new Blob([text]);
            const saveLink = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
            saveLink.href = url.createObjectURL(blob);
            // 设置 download 属性
            saveLink.download = fileName;
            saveLink.click();
        },
        handleonexport() {
            // change.exportmy({ cdkId: this.itemlist.cdkId }).then(res => {
            //     let fileName = this.itemlist.cdkName + ".txt"
            //     this.downloadText(fileName, res)
            // })
            if (this.active == 0) {
                this.item1.filter(item => {
                    this.text.push(this.itemlist.DOMAIN_NAME + item.CODE, item.CODE)
                })
                this.item2.filter(item => {
                    this.text.push(this.itemlist.DOMAIN_NAME + item.CODE, item.CODE)
                })
                this.item3.filter(item => {
                    this.text.push(this.itemlist.DOMAIN_NAME + item.CODE, item.CODE)
                })
            } else if (this.active == 1) {
                this.item1.filter(item => {
                    this.text.push(this.itemlist.DOMAIN_NAME + item.CODE, item.CODE)
                })
            } else if (this.active == 2) {
                this.item2.filter(item => {
                    this.text.push(this.itemlist.DOMAIN_NAME + item.CODE, item.CODE)
                })
            } else {
                this.item3.filter(item => {
                    this.text.push(this.itemlist.DOMAIN_NAME + item.CODE, item.CODE)
                })
            }
            this.downloadText("兑换码", this.text.join("\n"))
        }
    },
    mounted() {
    },
    created() {
        this.itemlist = JSON.parse(decodeURIComponent(this.$route.query.item))
        console.log(this.itemlist);
        this.checked = this.itemlist.COUNT == 1 ? true : false
        this.handle()
    }
}
</script>
<style scoped lang="less">
.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 150px;
}

.detail {
    height: 100%;
    background: #F1F1F1FF;
    position: fixed;
    width: 100%;
    overflow-y: scroll;

    .limg {
        width: 311px;
        float: right;
        position: relative;
    }

    .van-button::before {
        background-color: rgb(255, 86, 51);
    }

    .content {
        margin-top: 30px;
        position: absolute;
        width: 100%;
        z-index: 100;
        padding-bottom: 20px;

        .top {
            font-weight: bold;
            display: flex;
            width: 90%;
            margin: 0 auto;
            justify-content: space-between;
        }

        .items-list {

            .items {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background: white;
                width: 90%;
                margin: 0 auto;
                padding: 15px;
                box-sizing: border-box;
                border-bottom: 1px solid #F1F1F1FF;
            }
        }

        .title {
            width: 90%;
            margin-top: 11px;
            background-image: linear-gradient(to right, #FF7A35, #FF4F33);
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: white;
            box-sizing: border-box;
            padding: 10px;
            border-radius: 10px 10px 0 0;

            .title-right {
                opacity: 50%;
            }
        }

        .centre {
            width: 90%;
            margin: 0 auto;
            margin-top: 15px;

            .mar1 {
                border-radius: 10px 10px 0 0;

                .tes {
                    width: 150px;
                    /* 请根据实际需求设定宽度 */
                    white-space: nowrap;
                    /* 强制文本在一行内显示 */
                    overflow: hidden;
                    /* 隐藏超出容器的内容 */
                    text-overflow: ellipsis;
                    /* 当文本溢出时显示省略号 */
                }

                .textinput {
                    border: none;
                    text-align: right;


                }
            }

            .mar2 {
                border-radius: 0 0 10px 10px;
            }
        }

        .classify {
            width: 90%;
            margin: 0 auto;
            margin-bottom: 50px;

            ::v-deep(.van-tabs__nav--line) {
                background: #F1F1F1;
            }

            .middle {
                margin-top: 20px;
                border-radius: 10px;
                box-sizing: border-box;
                background: white;
                margin: 0 auto;
                padding: 15px;
                box-sizing: border-box;

                .tip {
                    display: flex;
                    align-items: center;
                }

                .http {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 10px;
                    color: #999999;
                }

                .redeem {
                    margin-top: 8px;
                    color: #999999;
                }

                .floorbto {
                    margin-top: 20px;
                    color: #666666;
                    background: white;

                    .timer {
                        padding-bottom: 12px;
                        padding-top: 12px;
                    }

                    .account {
                        padding-top: 12px;
                    }

                    .timer,
                    .account {
                        display: flex;
                        justify-content: space-between;
                        border-top: 1px solid #F1F1F1;
                    }
                }
            }

        }

        .floor {
            width: 100%;
            background: white;
            position: fixed;
            bottom: 0;

            .fr {
                display: flex;
                align-items: center;
                padding: 10px;
                justify-content: right;
            }
        }

    }
}

.van-tabs {
    ::v-deep(.van-tabs__content) {
        margin-top: 15px;
    }
}
</style>